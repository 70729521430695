<template>
    <div class="page">
        <div class="header">
            <el-page-header @back="$router.go(-1)" content="专家登记"/>
        </div>
        <el-row style="margin:15px 0" :gutter="10">
            <el-col :xs="24" :sm="18" :md="18">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家登记</span>
                    </div>
                    <el-form :model="form" ref="form" :rules="rules" label-width="70px">
                        <el-row style="display: flex">
                            <div style="width: 100%">
                                <el-form-item label="禁用" >
                                    <el-switch v-model="form.disabled" >
                                    </el-switch>
                                </el-form-item>
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="form.name"></el-input>
                                </el-form-item>
                                <el-form-item label="身份证号" prop="idnum">
                                    <el-input v-model="form.idnum"></el-input>
                                </el-form-item>
                                <el-form-item label="分类">
                                    <selectExpertCategory v-model="form.category" :multiple="false" />
                                </el-form-item>
                            </div>
                            <div >
                                <el-form-item label-width="20px">
                                    <el-upload
                                            class="avatar-uploader"
                                            action="/api/upload/image"
                                            :show-file-list="false"
                                            :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                        <img v-if="form.image" :src="form.image" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </el-row>
                        <el-form-item label="科室">
                            <selectExpertDepartment v-model="form.department" :multiple="false" />
                        </el-form-item>
                        <el-form-item label="专业">
                            <selectExpertMajor v-model="form.major" :multiple="false" />
                        </el-form-item>
                        <el-form-item label="职称">
                            <selectExpertTitle v-model="form.title" :multiple="false" />
                        </el-form-item>
                        <el-form-item label="学历">
                            <selectExpertEducation v-model="form.education" :multiple="false" />
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="form.contact"></el-input>
                        </el-form-item>
                        <el-form-item label="简介">
                            <el-input v-model="form.summary" type="textarea"
                                      :rows="4"></el-input>
                        </el-form-item>
                        <el-form-item label-width="20px">
                            <el-button type="primary" @click="onSubmit" :loading="loading">提 交</el-button>
                            <el-button @click="cancel">取 消</el-button>
                            <el-button type="danger" @click="handeDel" v-if="$route.params.id" style="float: right">删 除</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import SelectExpertEducation from "../../components/form/selectExpertEducation";
    export default {
        components: {SelectExpertEducation},
        data() {
            return {
                loading: false,
                rules: {
                    name: [
                        {required: true, message: '名称', trigger: 'blur'},
                    ]
                },
                form: {
                    disabled:false,
                    name: '',
                    category: '',
                    department: '',
                    major: "",
                    title: '',
                    education: '',
                    contact: '',
                    summary: '',
                    image: '',
                    idnum: ''
                },
                dictList: []
            }
        },
        methods: {
            handleAvatarSuccess(res, file) {
                if(res.code==200) {
                    this.form.image = res.data;
                }else {
                    this.$message.error(res.msg)
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                // if (!isLt2M) {
                //     this.$message.error('上传头像图片大小不能超过 2MB!');
                // }
                return true;
            },
            cancel() {
                this.$router.push('/main/expert/expert')
            },
            handeDel(){
                this.$http.delete(`/expert/${this.$route.params.id}/`)
                    .then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success(resp.data.msg)
                            this.$router.push('/main/expert/expert')
                        }
                    }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
            },
            onSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$http.post('/expert', this.form)
                            .then(resp => {
                                this.$message.success(resp.data.msg)
                                if (resp.data.code == 200) this.$router.push('/main/expert/expert')
                            }).catch(err => {
                            this.$message.error(err.message)
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            async getData() {
                try {
                    let pk = this.$route.params.id
                    if (pk) {
                        const resp = await this.$http.get(`/expert/${pk}`)
                        if (resp.data) {
                            this.form = resp.data
                        }
                        //     this.$message.error(resp.data.msg)
                        // } else {
                        // }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getSelect() {
                try {
                    const resp = await this.$http.get(`/dict?page=1&pageSize=500`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.dictList = resp.data.rows
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getSelect()
            this.getData()
        },
    }
</script>

<style  lang="scss" scoped>
    .avatar-uploader .el-upload {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 130px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }
    .avatar {
        width: 130px;
        height: 150px;
        display: block;
    }
    @media (max-width: 767px) {
        .avatar-uploader-icon {
            width: 110px;
        }

    }
</style>
